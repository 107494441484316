import React from "react";
import { Container, Form } from "react-bootstrap";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import articlesData from "./announcement";
import "./dashboard.css";

class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = { year: "all" };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ year: event.target.value });
    event.preventDefault();
  }

  render() {
    return (
      <Container>
        <div className="jumbotron">
          <MDBRow>
            <MDBCol></MDBCol>
            <MDBCol size="sm-2">
              <Form.Select
                size="lg"
                value={this.state.year}
                onChange={this.handleChange}
              >
                <option value="all">選擇年份</option>
                <option value="2024">2024</option>
                <option value="2023">2023</option>
                <option value="2022">2022</option>

                {/* <option value="2021">2021</option>
                <option value="2020">2020</option> */}
              </Form.Select>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MessageBlock contentArray={articlesData} year={this.state.year} />
          </MDBRow>
        </div>
      </Container>
    );
  }
  componentDidMount() {}
}

const Message = (props) => {
  let messageList = [];
  for (let i = 0; i < props.content.length; i++) {
    messageList.push(<p key={i}>{props.content[i]}</p>);
  }
  return (
    <>
      <Container>
        <div className="bordertron square border mt-3">
          <MDBRow>
            <MDBCol className="text-mid">
              {!props.hideDate && (
                <div className="sticker">
                  {props.date.year}年{props.date.month}月{props.date.day}日
                </div>
              )}
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <h3 className="my-3">{props.title}</h3>
          </MDBRow>
          <MDBRow>
            <div className="text-small my-3">{messageList}</div>
          </MDBRow>
        </div>
      </Container>
    </>
  );
};

const FirstMessage = () => {
  return (
    <Message
      hideDate={true}
      title={articlesData[0].title}
      content={articlesData[0].content}
    />
  );
};

const MessageBlock = (props) => {
  let contents = [];
  for (let i = 0; i < props.contentArray.length; i++) {
    if (
      props.year === props.contentArray[i].date.year.toString() ||
      props.year === "all"
    ) {
      contents.push(
        <Message
          key={i}
          date={props.contentArray[i].date}
          title={props.contentArray[i].title}
          content={props.contentArray[i].content}
        />
      );
    }
  }
  return <>{contents}</>;
};

export { Dashboard, FirstMessage };
