import { Container } from "react-bootstrap";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardImage,
  MDBRipple,
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";

import frontImage from "../images/new_clinic/clinic_front.jpeg";
import doctorImage from "../images/doctor.jpg";
import orImage from "../images/new_clinic/good_room.jpeg";
import shelfImage from "../images/new_clinic/living.jpeg";

import "./cards.css";

function Cards() {
  return (
    <Container>
      <div className="jumbotron">
        <MDBRow className="row-cols-md-4 row-cols-2 g-4 text-center">
          <MDBCol className="justify-content-center d-flex">
            <MDBCard className="card-style h-100" fluid>
              <MDBRipple
                rippleColor="light"
                rippleTag="div"
                className="hover-overlay"
              >
                <MDBCardImage
                  src={shelfImage}
                  fluid
                  alt="..."
                  className="card-img"
                />
                <a href="/schedule">
                  <div
                    className="mask"
                    style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                  ></div>
                </a>
              </MDBRipple>

              <MDBCardBody>
                <MDBCardTitle>門診時間</MDBCardTitle>
                {/* <MDBCardText>永光眼科診所原為淡水日聖眼科診所...</MDBCardText> */}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol className="justify-content-center d-flex">
            <MDBCard className="card-style h-100" fluid>
              <MDBRipple
                rippleColor="light"
                rippleTag="div"
                className="bg-image hover-overlay"
              >
                <MDBCardImage
                  src={frontImage}
                  fluid
                  alt="..."
                  className="card-img"
                />
                <a href="/about">
                  <div
                    className="mask"
                    style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                  ></div>
                </a>
              </MDBRipple>

              <MDBCardBody>
                <MDBCardTitle>診所介紹</MDBCardTitle>
                {/* <MDBCardText>永光眼科診所原為淡水日聖眼科診所...</MDBCardText> */}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol className="justify-content-center d-flex">
            <MDBCard className="card-style h-100" fluid>
              <MDBRipple
                rippleColor="light"
                rippleTag="div"
                className="bg-image hover-overlay"
              >
                <MDBCardImage
                  src={orImage}
                  fluid
                  alt="..."
                  className="card-img"
                />
                <a href="/services">
                  <div
                    className="mask"
                    style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                  ></div>
                </a>
              </MDBRipple>

              <MDBCardBody>
                <MDBCardTitle>主治項目</MDBCardTitle>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol className="justify-content-center d-flex">
            <MDBCard className="card-style h-100" fluid>
              <MDBRipple
                rippleColor="light"
                rippleTag="div"
                className="bg-image hover-overlay"
              >
                <MDBCardImage
                  src={doctorImage}
                  fluid
                  alt="..."
                  className="card-img"
                />
                <a href="/profile">
                  <div
                    className="mask"
                    style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                  ></div>
                </a>
              </MDBRipple>

              <MDBCardBody>
                <MDBCardTitle>主治醫師</MDBCardTitle>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </div>
    </Container>
  );
}

export default Cards;
