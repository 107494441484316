import React from "react";
import { useLocation } from "react-router-dom";
import { Container, Image, Nav, Navbar, NavDropdown, Offcanvas } from "react-bootstrap";
import { MDBIcon } from "mdb-react-ui-kit";
import EyeBrand from "../images/brand.png";

import "./navbar.css";

function MyNavbar() {
  const pathObject = useLocation();
  const infoPages = ["/about", "/profile", "/album", "/schedule"];

  const isInfo = infoPages.includes(pathObject.pathname);
  const isMyopia = pathObject.pathname.startsWith("/myopia");
  return (
    <>
      <Navbar
        sticky="top"
        style={{ backgroundColor: "#C67983" }}
        className="d-flex py-0"
        expand="lg"
      >
        <Container fluid style={{ justifyContent: "start" }}>
          <Navbar.Toggle aria-controls="offcanvasNavbar-expand-lg">
            <MDBIcon fas icon="bars" size="2x" style={{ color: "white" }} />
          </Navbar.Toggle>
          <Navbar.Brand href="/home" className="py-0">
            <Image
              height={68}
              src={EyeBrand}
              // className="d-inline-block align-top"
            ></Image>
          </Navbar.Brand>

          <Navbar.Offcanvas
            aria-labelledby="offcanvasNavbarLabel-expand-lg"
            placement="start"
            style={{ width: "200px", backgroundColor: "#C67983" }}
          >
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
              <Navbar.Collapse className="d-flex">
                <Nav
                  defaultActiveKey={pathObject.pathname}
                  className="d-flex justify-content-end flex-grow-1"
                >
                  <Nav.Item>
                    <Nav.Link href="/home">首頁</Nav.Link>
                  </Nav.Item>
                  <NavDropdown
                    style={{ minWidth: "0px" }}
                    title="診所資訊"
                    className={isInfo ? "active" : ""}
                  >
                    <NavDropdown.Item href="/schedule">門診時間</NavDropdown.Item>
                    <NavDropdown.Item href="/about">診所介紹</NavDropdown.Item>
                    <NavDropdown.Item href="/profile">主治醫師</NavDropdown.Item>
                    <NavDropdown.Item href="/album">診所圖庫</NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Item>
                    <Nav.Link href="/services">診療項目</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/cataract">白內障</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/traffic">交通資訊</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/announce">診所公告</Nav.Link>
                  </Nav.Item>
                  <NavDropdown
                    style={{ minWidth: "0px" }}
                    title="近視控制"
                    className={isMyopia ? "active" : ""}
                  >
                    <NavDropdown.Item href="/myopia_1">角膜塑型</NavDropdown.Item>
                    <NavDropdown.Item href="/myopia_2">兒童日拋</NavDropdown.Item>
                    <NavDropdown.Item href="/myopia_3">近視鏡片</NavDropdown.Item>
                    <NavDropdown.Item href="/myopia_4">長效散瞳</NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Item>
                    <Nav.Link href="/articles">保健知識</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/contact">聯絡我們</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="https://www.facebook.com/everlighteye/">
                      <MDBIcon fab icon="facebook" size="2x" style={{ color: "blue" }} />
                    </Nav.Link>
                  </Nav.Item>
                  {/* <MDBBtn
                    size="lg"
                    style={{ backgroundColor: "blue" }}
                    className="nav-button"
                    href="https://www.facebook.com/everlighteye/"
                  >
                    <MDBIcon fab icon="facebook-f" />
                  </MDBBtn> */}
                  {/* <MDBBtn
                    size="md"
                    style={{ backgroundColor: "green" }}
                    className="nav-button"
                    href="https://lin.ee/GqDFQ0E"
                  > */}
                  <Nav.Item>
                    <Nav.Link href="https://lin.ee/GqDFQ0E">
                      <MDBIcon fab icon="line" size="2x" style={{ color: "green" }} />
                    </Nav.Link>
                  </Nav.Item>
                  {/* </MDBBtn> */}
                </Nav>
              </Navbar.Collapse>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default MyNavbar;
