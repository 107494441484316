import { Image } from "react-bootstrap";
import surgeonImage from "../../images/new_clinic/surgeon.jpeg";

function Article() {
  return (
    <>
      <div className="smalltron">
        <h2>醫師姐姐 沈詩詠：一隻眼關注病人，一隻眼守護家人</h2>
      </div>
      <div className="smalltron">
        <a href="https://xcareer.wordpress.com/2016/03/16/%e6%b2%88%e8%a9%a9%e8%a9%a0%e4%b8%80%e9%9a%bb%e7%9c%bc%e9%97%9c%e6%b3%a8%e7%97%85%e4%ba%ba%ef%bc%8c%e4%b8%80%e9%9a%bb%e7%9c%bc%e5%ae%88%e8%ad%b7%e5%ae%b6%e4%ba%ba%e3%80%80/">
          <Image src={surgeonImage} fluid />
        </a>
      </div>
    </>
  );
}

export default Article;
