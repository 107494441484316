function Article() {
  return (
    <>
      <div className="smalltron">
        <h2>急性結膜炎（流行性結膜炎）</h2>
      </div>
      <div className="smalltron text-mid">
        <p>急性結膜炎（俗稱紅眼症）常發生於春夏炎熱之際，但四季皆可能流行。</p>
        <p>潛伏期：3-10天，但出血性急性結膜炎可能在接觸患者後半天內發病。</p>
        <p>週期：平均10天(1-2週)。</p>
        <p>
          症狀：眼睛發紅、刺痛、流淚、畏光並且有大量粘稠分泌物排出，嚴重時會引起眼瞼腫脹及結膜上有偽膜發生，少數病人會造成角膜發炎與糜爛。
        </p>
        <p className="text-highlight">注意事項：</p>
        <p>
          本症為病毒感染，故須以本身之免疫能力使其復原，藥物只能幫助減輕症狀，並無法縮短病程，故宜多休息使體力恢復為最重要。
        </p>
        <p>角膜發炎及糜爛常導致視力不良，須到眼科醫師處詳細追蹤及檢查。</p>
        <p>分泌物應加以擦除，尤其有偽膜者更應到眼科醫師處去除。</p>
        <p>
          多為接觸傳染，所以與病人接觸後需常洗手，患者用過之器具最好加以隔離清洗，避免間接感染。
        </p>
        <p>已感染者勿用手揉眼睛，並遵照醫生囑咐治療。</p>
      </div>
    </>
  );
}

export default Article;
