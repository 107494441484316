import React from "react";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";

import "./table.css";

const Table = () => {
  return (
    <MDBTable className="table-fixed">
      <MDBTableHead style={{ background: "#586c50", color: "white" }}>
        <tr>
          <th scope="col">時間</th>
          <th scope="col">一</th>
          <th scope="col">二</th>
          <th scope="col">三</th>
          <th scope="col">四</th>
          <th scope="col">五</th>
          <th scope="col">六</th>
          <th scope="col">日</th>
        </tr>
      </MDBTableHead>
      <MDBTableBody style={{ color: "#586c50" }}>
        <tr>
          <th scope="row">09:00 - 12:00</th>
          <td>
            <i style={{ color: "red" }} className="fas fa-check" />
          </td>
          <td>
            <i className="fas fa-check" />
          </td>
          <td>
            <i style={{ color: "red" }} className="fas fa-check" />
          </td>
          <td>
            <i className="fas fa-check" />
          </td>
          <td>
            <i style={{ color: "red" }} className="fas fa-check" />
          </td>
          <td>
            <i className="fas fa-check" />
          </td>
          <td></td>
        </tr>
        <tr>
          <th scope="row">14:30 - 17:30</th>
          <td>
            <i style={{ color: "red" }} className="fas fa-check" />
          </td>
          <td>
            <i className="fas fa-check" />
          </td>
          <td>
            <i className="fas fa-check" />
          </td>
          <td>
            <i className="fas fa-check" />
          </td>
          <td>
            <i style={{ color: "red" }} className="fas fa-check" />
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <th scope="row">18:30 - 21:00</th>
          <td>
            <i className="fas fa-check" />
          </td>
          <td>
            <i className="fas fa-check" />
          </td>
          <td>
            <i className="fas fa-check" />
          </td>
          <td>
            <i className="fas fa-check" />
          </td>
          <td>
            <i style={{ color: "red" }} className="fas fa-check" />
          </td>
          <td></td>
          <td></td>
        </tr>
      </MDBTableBody>
    </MDBTable>
  );
};

export default Table;
