import React from "react";
import { Container } from "react-bootstrap";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import blogData from "./blogs";
import "./dashboard.css";

class Blogboard extends React.Component {
  render() {
    return (
      <Container>
        <div className="jumbotron">
          <MDBRow>
            <MessageBlock contentArray={blogData} />
          </MDBRow>
        </div>
      </Container>
    );
  }
  componentDidMount() {}
}

const Message = (props) => {
  return (
    <>
      <Container>
        <div className="bordertron square border mt-3 position-relative">
          <MDBRow>
            <MDBCol className="text-mid">
              <div className="sticker">
                {props.date.year}年{props.date.month}月{props.date.day}日
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <a className="stretched-link" href={props.location}>
              <h3 className="my-3">{props.title}</h3>
            </a>
          </MDBRow>
        </div>
      </Container>
    </>
  );
};

const MessageBlock = (props) => {
  let contents = [];
  for (let i = 0; i < props.contentArray.length; i++) {
    contents.push(
      <Message
        key={i}
        id={i}
        date={props.contentArray[i].date}
        title={props.contentArray[i].title}
        location={props.contentArray[i].location}
      />
    );
  }
  return <>{contents}</>;
};

export default Blogboard;
