import { Image, Container } from "react-bootstrap";
import AdImage from "../../images/purpleAd.png";
import image2 from "../../images/purple2.png";
import image3 from "../../images/purple3.png";
import image4 from "../../images/purple4.png";
import image5 from "../../images/purple5.png";
import image6 from "../../images/purple6.png";

function Article() {
  return (
    <Container>
      <div className="jumbotron text-mid">
        <div className="smalltron">
          <h2>學童近視專用日拋</h2>
        </div>
        <div className="center">
          <Image src={AdImage} />
        </div>
        <div className="smalltron">
          <ul>
            <li>
              專為孩童設計，同時可達到控制近視及矯正視力的專利光學設計。經多國衛生主管機關核准，可減緩近視增加的速度，避免高度近視的發生。
            </li>
            <li>
              簡單舒適的近視控制新選擇：白天戴軟式，每天拋棄，再也不用擔心每天清洗鏡片和鏡片損壞的巨大壓力。不改變角膜形狀，同時提供孩子清晰的視力且控制近視，減緩近視加深的速度。
            </li>
          </ul>
          <h3>學童近視控制專用日拋三大優勢：</h3>
          <ol>
            <li>經多國衛生主管機關核准，有效控制近視的日拋軟式隱形眼鏡</li>
            <li>專利技術減緩眼軸長增加，減緩近視度數加深</li>
            <li>白天戴、免清洗、安全衛生、沒有眼鏡限制</li>
          </ol>
        </div>
        <div className="smalltron">
          <h3>學童近視控制專用日拋：</h3>
          <p>
            透過專利技術，減緩眼軸長度增加的速度，不讓眼睛像吹氣球不斷拉長，降低因眼軸拉長高度近視造成的疾病風險。
          </p>
        </div>
        <div className="center">
          <Image src={image2} />
        </div>
        <div className="smalltron">
          <h3>學童近視控制專用日拋成效：</h3>
          <p>臨床研究顯示，學童近視控制專用日拋能持續三年有效控制近視加深。</p>
        </div>
        <div className="center">
          <Image src={image3} />
        </div>
        <div className="center">
          <Image src={image4} />
        </div>
        <div className="smalltron">
          <h3>學童近視控制專用日拋如何佩戴：</h3>
          <p>建議每天至少配戴10小時，不超過15小時；每週至少配戴6天。</p>
        </div>

        <div className="center">
          <Image src={image5} />
        </div>
        <div className="smalltron">
          <h3>近視控制與學童近視控制專用日拋 Q&A:</h3>
        </div>
        <div className="center">
          <Image src={image6} />
        </div>
      </div>
    </Container>
  );
}

export default Article;
