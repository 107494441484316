import { Container, Image } from "react-bootstrap";
import eyeballImage from "../../images/eyeball.png";
import eyeballImage2 from "../../images/eyeball2.png";

function Article() {
  return (
    <Container>
      <div className="jumbotron text-mid">
        <div className="smalltron">
          <h2>兒童近視控制鏡片</h2>
          <h3>利用周邊離焦設計，帶孩子遠離近視：兒控鏡片</h3>
        </div>

        <div className="smalltron">
          <p>
            近年來，視力保健意識起頭，相信許多家長帶孩子到眼科檢查發現孩子視力值不如從前時，都會特別焦慮與不安，並想幫孩子找到挽救且適合的方式，不讓惡視力】成為孩子成長路上的絆腳石。在3C產品的侵入下，孩子的眼前一片模糊，又無法避免學習時長時使用用平板及視訊軟體，家長們緊張地氛圍無更加升溫了，隨著科技日新月異、技術不斷成長，周邊離焦設計的出現，突然照亮了孩子眼前模糊的路，兒控鏡片可有效＂控制＂孩子的近視度數，避免度數持續攀升。
          </p>
          <p>
            近年來，視力保健意識起頭，相信許多家長帶孩子到眼科檢查發現孩子視力值不如從前時，都會特別焦慮與不安，並想幫孩子找到挽救且適合的方式，不讓「惡視力」成為孩子成長路上的絆腳石。在3C產品的侵入下，孩子的眼前一片模糊，又無法避免學習時長時使用用平板及視訊軟體，家長們緊張地氛圍無更加升溫了，隨著科技日新月異、技術不斷成長，周邊離焦設計的出現，突然照亮了孩子眼前模糊的路，兒控鏡片可有效控制孩子的近視度數，避免度數持續攀升。
          </p>
        </div>
        <div className="smalltron">
          <h3>兒控鏡片適合對象為：剛開始近視和已經近視的兒童</h3>
          <p>
            近視度數會一直到18至20歲才趨近於穩定，孩子罹患近視的年齡越小，近視的發展時使就越長遠，時間隨著家長與孩子一開始的不以為意而過去，進而變得越來越嚴重。兒童、青少年越早出現近視，日後發展成高度近視的可能性就越大。高度近視容易導致各種眼部疾病的發生，當意識到嚴重性時，結局往往不盡人意。
          </p>
        </div>
        <div className="smalltron">
          <h3>｢傳統近視眼鏡，恐用近視惡化。」</h3>
          <p>什麼是兒童控制鏡片？和一般近視鏡片哪裡不一樣？</p>
          <p>
            配戴一般單光鏡片的情況下，影像聚焦在視網膜的黃斑部上，但當影像是透過鏡片邊緣位置投射到視網膜邊緣時，影像會聚焦在視網膜後方，導致遠視離焦（焦點產生在視網膜後）的狀態。黃斑區周邊區域出現遠視離焦時，會增加眼軸快速增長的風險，更刺激眼軸長度的增加，近距離閱讀時（如看書、使用手機），較容易出現遠視離焦的情況，閱讀距離越近、時間越長，眼睛的疲憊感就大大提升，此時為了要看清楚物體，眼睛必須距離目標物更近，遠視離焦的影響就越大，眼軸增長過快的風險亦會增加，反而導致近視惡化。
          </p>
        </div>
        <div className="center">
          <Image src={eyeballImage} />
        </div>
        <div className="smalltron">
          <p>
            兒控鏡片為「周邊視力理論」鏡片，是一種非侵入式且安全度極高的兒童近視管理鏡片，讓近視的孩子在配戴眼鏡的狀況下，能夠清楚視物，同時也可以避免近視度數再加深。兒控鏡片和一般近視鏡片差異為，在配戴時，中央成像均為標準中心點，影像落在視網膜上，而兒控鏡片同時可以使周邊影像焦點落在視網膜前方，形成近視周邊離焦的效果，搭配好的用眼習慣以及低濃度atropine眼藥水，可以有效達到控制眼軸持續往後生長並形成穩定的近視離焦，以減緩近視度數加深。
          </p>
        </div>
        <div className="smalltron">
          <h3>眼軸過長對眼睛健康有什麼影響？</h3>
          <p>
            一般情況下，眼軸越長，近視度數就越深。正常眼軸長為23-24mm，有研究指出眼軸超過26mm會增加患上眼疾而視力受損的風險，例如：青光眼、白內障及視網膜疾病。
          </p>
        </div>
        <div className="smalltron">
          <h3>兒控鏡片治療原理？什麼是周邊離焦呢？</h3>
          <p>
            周邊離焦鏡片的近視度數會隨著遠離光心而遞減，以修正周邊遠視離焦的情況，並幫助形成周邊近視離焦，配戴時，如同心圓原理，中央成像為視網膜上，周邊影像則會落在成像前，減少睫狀肌收縮過度，減緩兒童近視度數增加的速度。
          </p>
        </div>
        <div className="smalltron">
          <h3>驗配過程：</h3>
          <p>
            驗光師精準驗配度數、眼軸長度等數據，在由專業醫生評估是否符合配戴標準後，由驗光師進行驗配及鏡片功能專業分析。
          </p>
          <p>取件時，驗光師教導正確用眼習慣和鏡片保養衛教。</p>
          <p>
            回診時（一至兩個月需複診一次），定期檢測眼軸長度，再綜合其他相關數據作深入分析，讓專業醫師以及驗光師可以更有效地評估控制成效及制定跟進方案。
          </p>
        </div>
        <div className="center">
          <Image src={eyeballImage2} />
        </div>
        <div className="smalltron">
          <h3>及早正確介入是預防高度近視的關鍵</h3>
          <p>
            及早發現孩子近視，除了能盡早對症下藥，提高更好的生活品質之外，也可抑制近視加深，降低未來罹患高度近視的潛在風險。
            經研究證實，每日增加兩小時戶外活動時使可減少近視發生率，並且能減少兒童室內近距離用眼時間，有助於延緩近視加深的速度。
          </p>
        </div>
        <div className="smalltron">
          <h3>配了兒控鏡片之後，度數和眼軸就百分之百不會增加了嗎？</h3>
          <p>
            不論選擇何種控制近視方案，除了正確的矯正以及用眼時慣都是非常重要的，還要搭配良好的作息，不當的用眼或是閱讀時光線不良、姿勢不正確等，都會影響到日後度數控制的發展喔！
          </p>
        </div>
        <div className="smalltron">
          <h3>適用對象：</h3>
          <ol>
            <li>無法接受硬式隱形眼鏡帶來的異物與不適感</li>
            <li>無法配戴侵入性軟式隱形眼鏡</li>
            <li>使用一般鏡片，近視度數卻持續增加</li>
            <li>4-18歲的患者，把握黃金治療期</li>
          </ol>
        </div>
      </div>
    </Container>
  );
}

export default Article;
