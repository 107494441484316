import { Container, Image } from "react-bootstrap";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import adsImage from "../images/ads.JPG";
import alconImage from "../images/centurion_poster.png";

function Content() {
  return (
    <>
      <div className="banner">
        <h1 className="text-banner">白內障</h1>
      </div>

      <Container>
        <div className="jumbotron">
          <MDBRow className="row-cols-1 row-cols-md-2">
            <MDBCol className="d-flex flex-column" lg={8}>
              <p className="text-mid">
                本院採取目前最先進的
                <span style={{ color: "green" }}>
                  微創小切口白內障超音波晶體乳化術
                </span>
                並引進新型高階、高精密、高解析度的
                <span style={{ color: "green" }}>
                  徠卡(Leica)眼科手術顯微鏡
                </span>
                。開刀房內使用
                <a href="https://www.e-sun.com.tw/zh-tw/product-513183/760-E-SUN%E9%86%AB%E7%94%A8%E5%86%8D%E5%BE%AA%E7%92%B0%E7%B4%AB%E5%A4%96%E7%B7%9A%E7%A9%BA%E6%B0%A3%E6%B7%A8%E5%8C%96%E6%A9%9F-%E5%8C%97%E5%B8%82%E8%A1%9B%E5%99%A8%E5%BB%A3%E5%AD%97%E7%AC%AC107120451%E8%99%9F.html">
                  760 E-SUN醫用再循環紫外線空氣淨化機
                </a>
                進行消毒。
              </p>
            </MDBCol>
            <MDBCol lg={4}>
              <Image src={adsImage} fluid></Image>
            </MDBCol>
          </MDBRow>
          <br></br>
          <br></br>
          <MDBRow className="row-cols-1 row-cols-md-2">
            <MDBCol lg={4}>
              <Image src={alconImage} fluid />
            </MDBCol>
            <MDBCol className="d-flex" lg={8}>
              <p className="text-mid ">
                在2022年1月份起，為提高服務品質，加倍服務白內障手術的患者，本診所沈詩詠院長添購最新
                <a href="https://professional.myalcon.com/cataract-surgery/cataract-equipment/centurion-vision-system/">
                  Alcon第二代『Centurion® vision system II
                  穩復明白內障晶體乳化儀』
                </a>
                合併主動『Active Sentry ™ handpiece
                主動壓力感知回饋元件』，讓手術更安全及更有效率。
              </p>
            </MDBCol>
          </MDBRow>
          <br></br>
          <MDBRow>
            <h2>白內障成因</h2>
            <hr />
            <p className="text-mid">
              因水晶體老化出現混濁，而出現視力不穩定、模糊、近視突然大幅增加、複視、畏光、夜間炫光，物體顏色變暗等現象，並且無法以眼鏡矯正。
              可區分為老年性、先天性、藥物性、外傷性、或其他眼睛或身體疾病併發造成。最常見原因是受到紫外線以及高能量藍光照射，尤其近來3C產品高度使用，使年輕族群也提早發生白內障，不是只有中老年人才會罹患。
              <br></br>
              <br></br>
              手術治療為白內障唯一有效的治療方法。過熟的白內障會引發青光眼，易導致失明。過熟的白內障也會增加醫師手術的困難度與手術時間，風險自然提高。
            </p>
          </MDBRow>
        </div>
      </Container>
    </>
  );
}

export default Content;
