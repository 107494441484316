import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";
import { Container, Image } from "react-bootstrap";

import alconImage from "../images/alconMachine.png";
import childImage from "../images/child.jpg";
import octImage from "../images/oct3.jpg";
import surgeonImage from "../images/new_clinic/surgeon.jpeg";
import tomeyImage from "../images/tomey.png";
import topconImage from "../images/topcon.jpeg";

import Button from "../components/button";

const Banner = () => {
  return (
    <div className="banner">
      <h1 className="text-banner">診療項目</h1>
    </div>
  );
};

function Services() {
  const greenBlockStyle = { backgroundColor: "rgba(88, 108, 80, 0.25)" };
  const orangeBlockStyle = { backgroundColor: "rgba(213, 104, 61, 0.25)" };
  return (
    <>
      <Banner />
      <Button />
      <Container>
        <div className="jumbotron">
          <MDBRow className="align-items-center">
            <MDBCol lg="6">
              <Image src={surgeonImage} fluid />
            </MDBCol>
            <MDBCol lg="6">
              <MDBCard>
                <MDBRow className="g-0">
                  <MDBCol md="5">
                    <a href="/cataract">
                      <MDBCardImage src={alconImage} alt="..." fluid />
                    </a>
                  </MDBCol>
                  <MDBCol md="7" style={greenBlockStyle}>
                    <MDBCardBody className="d-flex flex-column">
                      <MDBCardTitle>
                        微創白內障手術 - <br></br>
                        超音波晶體乳化術
                      </MDBCardTitle>
                      <MDBCardText className="text-small">
                        本院採取目前最先進的超音波晶體乳化術並引進新型高階、高精密、高解析度的徠卡(Leica)眼科手術顯微鏡。
                      </MDBCardText>
                      <MDBCardText className="text-muted text-small">
                        <a href="/cataract">詳細介紹</a>
                      </MDBCardText>
                    </MDBCardBody>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </div>

        <div className="jumbotron">
          <MDBRow className="align-items-center">
            <MDBCol lg="7">
              <MDBCard>
                <MDBRow className="g-0">
                  <MDBCol md="5">
                    <MDBCardImage src={octImage} alt="..." fluid />
                  </MDBCol>
                  <MDBCol md="7" style={greenBlockStyle}>
                    <MDBCardBody className="d-flex flex-column">
                      <MDBCardTitle>光學同調電腦斷層掃描OCT</MDBCardTitle>
                      <MDBCardText className="text-small">
                        可以精確檢查黃斑部皺褶、黃斑部破洞，視網膜出血、視網膜水腫、視神經萎縮，並予定量化分析及高解析的影像呈現，有利於
                        <b>青光眼、黃斑部病變、視網膜病變</b>
                        等疾病的發現與追蹤。
                      </MDBCardText>
                      <MDBCardText className="text-muted text-small">
                        <a href="https://www.nidek-intl.com/items/optical-coherence-tomography-fundus-camera-retina-scan-duo2/">
                          儀器介紹
                        </a>
                      </MDBCardText>
                    </MDBCardBody>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </MDBCol>
            <MDBCol lg="5">
              <MDBCard>
                <MDBRow className="g-0">
                  <MDBCol md="5">
                    <MDBCardImage src={tomeyImage} alt="..." fluid />
                  </MDBCol>
                  <MDBCol md="7" style={orangeBlockStyle}>
                    <MDBCardBody className="d-flex flex-column">
                      <MDBCardTitle>
                        白內障術前評估與 <br></br>
                        學童近視控制
                      </MDBCardTitle>
                      <MDBCardText className="text-small">
                        本院引進TOMEY光學式眼軸量測儀，此儀器提供非接觸式的全方位精準光學掃描。
                      </MDBCardText>
                      <MDBCardText className="text-muted text-small">
                        <a href="https://www.tomey.de/products/oa-2000">儀器介紹</a>
                      </MDBCardText>
                    </MDBCardBody>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </div>
        <div className="jumbotron">
          <MDBRow className="align-items-center">
            <MDBCol lg="6">
              <MDBCard>
                <MDBRow className="g-0">
                  <MDBCol md="5">
                    <MDBCardImage src={topconImage} alt="..." fluid />
                  </MDBCol>
                  <MDBCol md="7" style={orangeBlockStyle}>
                    <MDBCardBody className="d-flex flex-column">
                      <MDBCardTitle>角膜疾病診斷</MDBCardTitle>
                      <MDBCardText className="text-small">
                        本院引進topcon
                        CA-800角膜分析儀，此儀器能夠準確地測量眼睛的角膜厚度和形狀，並提供詳細的角膜圖像。幫助醫師診斷和治療
                        <b>角膜疾病</b>。
                      </MDBCardText>
                      <MDBCardText className="text-muted text-small">
                        <a href="https://topconhealthcare.com/products/ca-800/">儀器介紹</a>
                      </MDBCardText>
                    </MDBCardBody>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </MDBCol>
            <MDBCol lg="6">
              <MDBCard>
                <MDBRow className="g-0">
                  <MDBCol md="5" className="align-items-center d-flex justify-content-center">
                    <a href="/cataract">
                      <MDBCardImage src={childImage} alt="兒童視力保健" fluid />
                    </a>
                  </MDBCol>
                  <MDBCol md="7" style={greenBlockStyle}>
                    <MDBCardBody className="d-flex flex-column">
                      <MDBCardTitle>兒童近視控制</MDBCardTitle>
                      <MDBCardText className="text-small">
                        本院針對<b>兒童近視</b>提供下列服務：
                      </MDBCardText>
                      <ol>
                        <li>
                          <a href="https://www.myok.com.tw/cornealplasticfilm-corneallens.html">
                            歐基里徳Euclid
                          </a>
                        </li>
                        (夜戴型角膜塑型隱形眼鏡)
                        <li>
                          <a href="https://coopervision.com.tw/%E5%85%92%E7%AB%A5%E8%BF%91%E8%A6%96%E6%8E%A7%E5%88%B6">
                            酷柏邁視兒MiSight 1 Day
                          </a>
                        </li>
                        (學童近視控制專用日拋)
                        <li>
                          <a href="https://www.essilor.com/tw-zh/products/stellest/">
                            ESSILOR依視路星趣控
                          </a>
                        </li>
                        (學童近視控制眼鏡)
                        <li>
                          <a href="https://www.hoyavision.com/hk/%E7%9C%BC%E9%8F%A1%E4%BD%A9%E6%88%B4%E8%80%85/miyosmart/">
                            HOYA MiYOSMART
                          </a>
                        </li>
                        (學童近視控制眼鏡)
                      </ol>
                    </MDBCardBody>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </div>
        <div className="jumbotron">
          <MDBRow className="align-items-center"></MDBRow>
        </div>
      </Container>
      <Container>
        <div className="jumbotron text-mid">
          <MDBRow>
            <MDBCol className="grid-block" style={orangeBlockStyle}>
              視網膜眼底檢查
            </MDBCol>
            <MDBCol className="grid-block" style={greenBlockStyle}>
              <p>光學同調電腦斷層掃描</p>
              <a
                className="stretched-link"
                href="https://www.nidek-intl.com/news-event/news/entry-4281.html"
              >
                OCT
              </a>
            </MDBCol>
            <MDBCol className="grid-block" style={orangeBlockStyle}>
              青光眼雷射治療
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol className="grid-block" style={greenBlockStyle}>
              乾眼症
            </MDBCol>
            <MDBCol className="grid-block" style={orangeBlockStyle}>
              <p>驗光師精配眼鏡</p>
              <p>及軟硬式隱形眼鏡</p>
            </MDBCol>
            <MDBCol className="grid-block" style={greenBlockStyle}>
              學童視力保健
            </MDBCol>
          </MDBRow>
        </div>
      </Container>
    </>
  );
}

export default Services;
