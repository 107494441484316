import { Image } from "react-bootstrap";
import fisherImage from "../../images/fisher.jpg";

function Article() {
  return (
    <>
      <div className="smalltron">
        <h2>海水反射紫外線易使釣客白內障!人工水晶體怎麼選?</h2>
      </div>
      <div className="smalltron">
        <a href="https://www.healthnews.com.tw/news/article/43640">
          <Image src={fisherImage} fluid></Image>
        </a>
      </div>
    </>
  );
}

export default Article;
