import React from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import Acute from "./articlePages/acute";
import Atropine from "./articlePages/atropine";
import Allergy from "./articlePages/allergy";
import Crystal from "./articlePages/crystal";
import Dryeye from "./articlePages/dryeye";
import Eyecare from "./articlePages/eyecare";

const ArticleRouter = () => {
  const pathObject = useLocation();
  let passage = <h2>抱歉，您所輸入的網址沒有內容</h2>;

  switch (pathObject.pathname) {
    case "/articles/atropine":
      passage = <Atropine />;
      break;
    case "/articles/dryeye":
      passage = <Dryeye />;
      break;
    case "/articles/allergy":
      passage = <Allergy />;
      break;
    case "/articles/acute":
      passage = <Acute />;
      break;
    case "/articles/eyecare":
      passage = <Eyecare />;
      break;
    case "/articles/crystal":
      passage = <Crystal />;
      break;
    default:
      passage = <h2>抱歉，您所輸入的網址沒有內容</h2>;
  }
  return (
    <>
      <div className="banner">
        <h1 className="text-banner">保健知識</h1>
      </div>
      <Container>
        <div className="jumbotron">{passage}</div>
      </Container>
    </>
  );
};

export default ArticleRouter;
