function Article() {
  return (
    <>
      <div className="smalltron">
        <h2>過敏性結膜炎</h2>
      </div>
      <div className="smalltron text-mid">
        <p>與傳染性結膜炎不同處，結膜下半部發炎為主，分泌物較稀白。</p>
        <p>
          <i className="far fa-hand-point-right" />{" "}
          好發於體質敏感的人，如氣喘病史，異位性皮膚炎、過敏性鼻炎者因接觸過敏原而發病，也就是非病毒或細菌引起，所以
          <span className="text-highlight">不會傳染</span>。
        </p>

        <p>
          <i className="far fa-hand-point-right" />{" "}
          由病程長短約分為急性，慢性與季節性幾種。
        </p>
        <p>
          <span className="text-highlight">急性過敏</span> —
          多因食物（不潔海鮮）﹑塵螨（絨布偶），摸寵物揉眼引起，數分鐘至數小時內快速發生眼紅、癢、甚至結膜水泡，眼皮腫脹等嚇人外觀，但是來的快去的也快，在數小時內可消解。
        </p>
        <p>
          <span className="text-highlight">慢性過敏</span> -
          因藥物如隱形眼鏡藥水，長期口服抗生素或其他藥物引起，症狀唯一般性紅癢，異物感等較為不適。
        </p>
        <p>
          <span className="text-highlight">季節性過敏</span> －
          換季等氣候轉變期間發生，多在春，秋兩季初期好發。常會合併有打噴嚏，皮膚癢，眼睛則以眼紅癢之外常見流淚畏光灼熱感等症狀。
        </p>
        <p>
          預防：各人體質不同，宜避免接觸過敏原，勿吃不新鮮食物，冷氣機的濾網常清洗，枕頭被套勤換洗，床頭少放易生塵螨的絨布玩偶，常用肥皂洗手與不要揉眼睛，少接觸寵物等。
        </p>
        <p>
          治療：以消炎、抗過敏眼用藥水、藥膏可以緩解症狀。使用專門去塵蟎的清潔棉片可預防並有效治療過敏性結膜炎。需按醫囑診斷治療，勿自行購藥亂點藥水。
        </p>
      </div>
    </>
  );
}

export default Article;
