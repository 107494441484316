function Article() {
  return (
    <>
      <div className="smalltron">
        <h2>長效型散瞳劑(Atropine)注意事項</h2>
      </div>
      <div className="smalltron text-mid">
        <p>
          因學習環境變遷，過早的才藝和補習教育、手機、電腦、平板、電視的普及，台灣學童普遍用眼過度疲勞，近視嚴重，使得散瞳劑的治療不失為近視最安全有效的方法，使用上請家長放心。
        </p>
        <ol>
          <li>
            藥水點後會有些微刺痛感，乃為正常現象。建議點後閉眼五分鐘，並擦拭掉溢出的藥水，以防眼皮過敏。
          </li>
          <li>
            藥效為長效（3-5）天,點後瞳孔較大，會有畏光現象。建議戶外活動，尤其是大晴天時，配戴抗UV太陽眼鏡或帽子，以防紫外線刺眼。
          </li>
          <li>
            因眼球睫狀肌放鬆，所以會有類似老花眼的現象，看近物會不清楚，建議坐直寫字、眼鏡在看近距離時取下（或使用較低度數眼鏡）以便書寫，看遠距離（如看黑板）時再配戴眼鏡。
          </li>
          <li>
            坊間流傳散瞳劑容易導致青光眼，傷及視網膜等等不當傳言，此為極少數體質特殊的案例，建議每1~2個月定期回診，可同時確保度數穏定舆用藥安全。
          </li>
          <li>
            長效型散瞳劑分別有自費的低濃度以及健保給付的較高濃度，由醫師建議使用。
          </li>
        </ol>
      </div>
    </>
  );
}

export default Article;
