import React from "react";
import GoogleMapReact from "google-map-react";
import "./map.css";

const api_key = "AIzaSyBIyonufTZaMhBj7J-lMMxH4eg7mDCdYUs";

const Map = ({ location, zoomLevel }) => (
  <div className="map">
    <div className="google-map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: api_key }}
        defaultCenter={location}
        defaultZoom={zoomLevel}
      >
        <Marker lat={location.lat} lng={location.lng} />
      </GoogleMapReact>
    </div>
  </div>
);

const Marker = (props) => {
  return (
    <>
      <div className="pin"></div>
      <div className="pulse"></div>
    </>
  );
};

export default Map;
