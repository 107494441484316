const articlesData = [
  {
    date: { year: 2021, month: 10, day: 21 },
    title: "長效型散瞳劑(Atropine)注意事項",
    location: "/articles/atropine",
  },
  {
    date: { year: 2021, month: 10, day: 14 },
    title: "乾眼症",
    location: "/articles/dryeye",
  },
  {
    date: { year: 2020, month: 10, day: 14 },
    title: "過敏性結膜炎",
    location: "/articles/allergy",
  },
  {
    date: { year: 2019, month: 10, day: 11 },
    title: "急性結膜炎(流行性結膜炎)",
    location: "/articles/acute",
  },

  {
    date: { year: 2019, month: 9, day: 25 },
    title: "海水反射紫外線易使釣客白內障!人工水晶體怎麼選?",
    location: "/articles/crystal",
  },
  {
    date: { year: 2019, month: 9, day: 17 },
    title: "醫師姐姐 沈詩詠：一隻眼關注病人，一隻眼守護家人",
    location: "/articles/eyecare",
  },
];

export default articlesData;
