function Article() {
  return (
    <>
      <div className="smalltron">
        <h2>乾眼症</h2>
      </div>
      <div className="smalltron text-mid">
        <p>
          <span className="text-highlight">眼晴乾澀、會癢、容易疲勞、變紅</span>
          是病人來眼科門診常見的抱怨，若不經過詳細的檢査，常誤以為只是一般慢性結膜炎，長期點藥無法治療而備感困擾。
        </p>
        <p>
          症狀：乾澀、癢、紅、容易疲勞外，也常因淚水分泌不足，角膜刺激而產生流淚的反向症狀。
        </p>
        <p>
          特別在冷氣房待久、戶外風吹較大、太陽光較強、或一天工作之後，在下午、晩上病症會更明顯。
        </p>
        <p>原因：</p>
        <ol>
          <li>因年紀漸長，淚腺分泌功能退化，淚液減少所致。</li>
          <li>
            因工業社會、空氣污染、飲食生活習慣改變、睡眠不足，加上現代人大量閱讀、開車、看手機、電腦、平板等用眼時間過久的所謂
            <span className="text-highlight">文明病</span>。
          </li>
          <li>長期戴隱形眼鏡或開過近視手術致淚膜不穏定。</li>
          <li>
            免疫系統異常、砂眼、眼瞼炎、眼瞼結構異常時之因素，以致淚水分泌不足或排除太快。
          </li>
        </ol>
        <p>治療：除了針對症狀點眼藥消炎外，下列應注意事項：</p>
        <ol>
          <li>
            適當眼睛休息，勿過度使用眼晴（電腦工作者每40分鐘宜閉眼5-10分鐘）
          </li>
          <li>
            人工淚液用作眼球表面水份之補充加強潤滑保護，並可沖洗掉眼球表面灰麈雜質，減少刺激發炎。
          </li>
          <li>補充魚油或用滴舒適（DIQUAS）以增加淚液分泌。</li>
          <li>熱敷以促進淚液穏定度。（每日早晚各2分鐘,40度溫度）</li>
          <li>角膜上皮保護，維生素A對角膜上皮角質化有舒緩作用且可滋潤眼睛。</li>
          <li>免疫療法：刺激大腦、淚腺間對神經傳導機制，使淚水正常分泌。</li>
          <li>
            將排淚管阻塞有可吸收與不可吸收材質或手術將眼皮縫合、拉緊等方式。
          </li>
        </ol>
        <p>
          基本上乾眼症雖屬不易根治的慢性病，但只要能改善用眼的習慣，依據個人體質及環境因素，遵照醫師指示用藥，還是可以獲得很好的控制、降低不適。
        </p>
      </div>
    </>
  );
}

export default Article;
