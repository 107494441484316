import React from "react";
import { Carousel, Container, Image } from "react-bootstrap";

import frontImage from "../images/new_clinic/clinic_front.jpeg";
import interiorImage from "../images/new_clinic/clinic_interior.jpeg";
import couchImage from "../images/new_clinic/couch.jpeg";
import counterImage from "../images/new_clinic/counter.jpeg";
import officeImage from "../images/new_clinic/office.jpeg";
import livingImage from "../images/new_clinic/glasses.jpeg";
import newMachineImage from "../images/new_clinic/good_room.jpeg";
import surgeonImage from "../images/new_clinic/surgeon.jpeg";

import alconImage from "../images/centurion_poster.png";
import certsImage from "../images/certs.JPG";
import hallImage from "../images/new_clinic/hallway.jpeg";
import shelfImage from "../images/new_clinic/living.jpeg";
import stuffImage from "../images/stuff.JPG";
import adsImage from "../images/ads.JPG";
import orImage from "../images/new_clinic/health_education_room.jpeg";
import plaqueImage from "../images/new_clinic/plaque.jpeg";

function Album() {
  return (
    <>
      <div className="banner">
        <h1 className="text-banner">診所圖庫</h1>
      </div>
      <Container>
        <div className="jumbotron">
          <Carousel className="carousel-wide" showControls>
            <Carousel.Item>
              <Image className="d-block w-100" src={livingImage} alt="休息室" />
            </Carousel.Item>
            <Carousel.Item>
              <Image
                className="d-block w-100"
                src={interiorImage}
                alt="診所內部"
              />
            </Carousel.Item>
            <Carousel.Item>
              <Image
                className="d-block w-100"
                src={couchImage}
                alt="診所內部"
              />
            </Carousel.Item>

            <Carousel.Item>
              <Image className="d-block w-100" src={officeImage} alt="診間" />
            </Carousel.Item>
          </Carousel>
        </div>
      </Container>

      <Container>
        <div className="jumbotron">
          <Carousel className="carousel-long">
            <Carousel.Item>
              <Image
                className="d-block w-100"
                src={frontImage}
                alt="診所外觀"
              />
            </Carousel.Item>
            <Carousel.Item>
              <Image
                className="d-block w-100"
                src={counterImage}
                alt="診所櫃檯"
              />
            </Carousel.Item>
            <Carousel.Item>
              <Image
                fluid
                className="d-block w-100"
                src={hallImage}
                alt="診所走廊"
              />
            </Carousel.Item>
            <Carousel.Item>
              <Image
                fluid
                className="d-block w-100"
                src={newMachineImage}
                alt="新驗光機器"
              />
            </Carousel.Item>
            <Carousel.Item>
              <Image
                fluid
                className="d-block w-100"
                src={alconImage}
                alt="診所圖片"
              />
            </Carousel.Item>

            <Carousel.Item>
              <Image
                fluid
                className="d-block w-100"
                src={certsImage}
                alt="診所證書"
              />
            </Carousel.Item>
            <Carousel.Item>
              <Image
                className="d-block w-100"
                src={shelfImage}
                alt="診所櫃子"
              />
            </Carousel.Item>
            <Carousel.Item>
              <Image
                className="d-block w-100"
                src={stuffImage}
                alt="診所商品"
              />
            </Carousel.Item>
            <Carousel.Item>
              <Image className="d-block w-100" src={adsImage} alt="服務項目" />
            </Carousel.Item>
            <Carousel.Item>
              <Image className="d-block w-100" src={orImage} alt="手術室" />
            </Carousel.Item>
            <Carousel.Item>
              <Image className="d-block w-100" src={plaqueImage} alt="匾額" />
            </Carousel.Item>
            {/* <Carousel.Item>
              <Image
                className="d-block w-100"
                src={surgeonImage}
                alt="沈醫師"
              />
            </Carousel.Item> */}
          </Carousel>
        </div>
      </Container>
    </>
  );
}

export default Album;
