import { Image, Container } from "react-bootstrap";
import MyopiaImage from "../../images/myopia.png";

function Article() {
  return (
    <Container>
      <div className="jumbotron text-mid">
        <div className="smalltron">
          <h2>角膜塑型片</h2>
          <h3>用充足的睡眠時間，延緩近視度數惡化的速度：角膜塑型片。</h3>
        </div>
        <div className="smalltron">
          <p>
            對抗3C普及化，孩子緊盯螢幕使近視度數快速加深，若沒有把握黃金治療期，將會面臨不可逆的高度近視，使眼睛疾病發生率大大提升，孩子的視力，我們來把關。
          </p>
        </div>
        <div className="smalltron">
          <h3>了解角膜塑型片：</h3>
          <p>
            角膜塑型片，也稱為『矯正角膜的科學方法』，是一種物理性、非手術的矯正方式，使用特殊醫療專用高透氧硬式夜戴型隱形眼鏡，根據配戴者眼球屈光度數、角膜曲率和角膜特性而量身訂製，利用晚上6至10小時的睡眠時間配戴塑型片，慢慢改變角膜形狀，使角膜變的平坦，達到穩定控制近視的效果。長期配戴還可以有效控制近視度數，是一種安全、簡單、有效的治療方式。
          </p>
          <p>角膜塑型片具有可逆性，若長時間停止配戴，眼睛就會慢慢恢復未配戴角膜塑型片前的狀態。</p>
        </div>
        <div className="smalltron">
          <h3>角膜塑型片治療原理：</h3>
          <p>
            利用角膜可塑性，將客製化多弧度設計的硬式隱形眼鏡壓平中心角膜，暫時性降低近視及散光度數，並改變光線進入眼睛之後的影像位子，同時產生周邊離焦的效果，進而有效改善視力，延緩眼軸增長的速度，在睡眠期間戴上角膜塑型片時，鏡片會在夜間對角膜進行塑型，使其改變角膜形狀，當角膜弧度穩定之後，配戴者在白天時，無需配戴眼鏡，就能擁有清晰的視覺表現，讓孩子在白天時，大大提升學習的專注度，日常運動和學習都輕鬆、有效率！
          </p>
          <p>
            根據研究顯示，近視度數增加的重要因素之一是眼軸增長，當眼軸增加1mm，近視度數會增加300度，配戴角膜塑型片患者與配戴一般無特殊設計鏡片者相比，使用角膜塑型片者，眼軸長度及近視度數上的增加，在兩年後明顯地較少，約為配戴一般眼鏡者的一半。
          </p>
        </div>
        <div className="smalltron">
          <h3>驗配過程：</h3>
          <p>
            由專業醫師以及驗光師，針對角膜形狀，角膜弧度，眼睛健康狀況等多項目檢查，評估是否適合配戴後，由醫生根據驗光師檢測出來的各項報告選擇最適合的試戴片進行試戴，透過螢光染色劑判讀染色圖形，辨別鏡片是否符合病患的眼角膜形狀，並找到最適合病患的鏡片直徑以及弧度。
          </p>
          <p>
            取件時，由驗光師詳細解說進行衛教，教導如何戴上鏡片和卸下，以及塑型片日常清洗與保養。回診時(一至兩個月需複診一次)，定期檢測眼軸長度以及角膜地形圖儀，再綜合其他相關數據，如近視度數、過往近視加深情況作深入分析，讓專業醫師以及驗光師可以更有效地評估控制成效及制定跟進方案。
          </p>
        </div>
        <div className="center">
          <Image src={MyopiaImage} />
        </div>
        <div className="smalltron">
          <h3>治療效果：</h3>
          <p>
            角膜形狀變化是漸進式的，隨著配戴時間的累積逐漸控制近視度數，矯正所需時間，會依照個人的角膜條件、近視度數和散光度數而定，一般在一至二週就有效果，完全矯正時間大約需１至３個月。
          </p>
        </div>
        <div className="smalltron">
          <h3>治療對象: </h3>
          <ol>
            <li>不想配戴眼鏡</li>
            <li>無法接受散瞳藥水帶來的畏光與不適感</li>
            <li>定期回診追蹤，度數上卻仍然不穩定</li>
            <li>雙眼不等視，想要不配戴框架眼鏡並可以解決不等視在用眼時的壓迫感</li>
            <li>年滿9歲</li>
          </ol>
          <p>※角膜塑型矯正效果依個人配戴狀況有所不同，請遵從醫護人員指示，並定期回診檢查。</p>
        </div>
      </div>
    </Container>
  );
}

export default Article;
